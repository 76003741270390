import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function PaymentAppPayu() {
    // https://trst.ink/api/paySuccessurlPayu
    const location = useLocation();
    const [phone, setPhone] = useState("");
    const [amount, setAmount] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [tokenId, setTokenId] = useState("");
    const [symbol, setSymbol] = useState("");
    const [type, setType] = useState("");


    const [hash, setHash] = useState("");
    const [txnId, setTxnId] = useState("");

    const [currentTime, setCurrentTime] = useState("");
    const [pay, setpay] = useState("Payment");
    const [btnPay, setbtnPay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isErrorMsg, setIsErrorMsg] = useState("");





   async function checkuserexist(token, mobile, amount){
        try {
            const data = await axios.post("https://backoffice.inrx.io/api/auth/checkUser", {
                tokenId: token,
                mobile: mobile,
                amountINR:amount
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                }
            })
              console.log(data,"data")
              return data?.data
        } catch (error) {
            setbtnPay(false)
            console.log(error,"error")
            return false
        }
    }

    useEffect(()=>{
        if(tokenId && phone){
            checkuserexist(tokenId,phone,amount).then((res)=>{
                console.log(res,"res")
                if(res?.success){
                    setIsErrorMsg(res?.message);
                    setbtnPay(true)
                }else{
                    setbtnPay(false)
                    setIsErrorMsg(res?.message);
                }
            }).catch((e)=>{
                setbtnPay(false)
                // setLoader(false)
                console.log(e)
                setIsErrorMsg("internal server error!");
            })
        }
    },[tokenId,phone])

    useEffect(() => {
        function getParameterByName(name, url) {
            name = name.replace(/[\[\]]/g, "\\$&");
            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
            const results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
        const amount = getParameterByName('amount', location.search);
        const phone = getParameterByName('phone', location.search);
        const email = getParameterByName('email', location.search);
        const name = getParameterByName('name', location.search);
        const token = getParameterByName('tokenId', location.search);
        const symbolurl = getParameterByName('symbol', location.search);
        const typeurl = getParameterByName('type', location.search);


         getPayuHash(name,email,amount,phone,token,symbolurl,typeurl).then((res)=>{
            if(res){
                setHash(res.hash)
                setTxnId(res.txnId)
            }
          
         }).catch((error)=>{
            console.log(error)
         })


        setPhone(phone)
        setAmount(amount)
        setEmail(email)
        setName(name)
        setTokenId(token || "")
        setSymbol(symbolurl || "")
        setType(typeurl || "")


        const currentDate = new Date();


        const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };
        const formattedDateTime = currentDate.toLocaleDateString('en-US', options);

        setCurrentTime(formattedDateTime)
    }, [location.search]);
    function generateRandomString() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var randomString = '';
        for (var i = 0; i < 6; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomString;
    }

    // Example usage:
    console.log(generateRandomString(), pay);


  async function getPayuHash(name,email,amount,phone,token, symbolUrl, typeurl){
    const data = {
        name:name, 
        email:email, 
        amount:amount, 
        phone:phone,
        udf1:token,
        udf2:symbolUrl,
        udf3:typeurl,
    }
        const response = await axios.post("https://trst.ink/api/payuHash", data);
        console.log(response,"response")
        if (response.status === 200) {
            return response.data
            // setHash(response.data.hash)
            // setTxnId(response.data.txnId)
            // setToggle(2)
        } else {
            return false
        }
  }

  const handleSubmit = (event) => {
    setLoader(true); // Loader enable
  };
    return (
        <div className="container mt-1">
            <div className="row justify-content-center align-items-center   pay-card">

                <div className="message-box _success _failed">

                    <div className="invdoice-card">
                    {/* //secure */}
                        <form method="POST" action="https://secure.payu.in/_payment" onSubmit={handleSubmit}>  
                            <input type="hidden" name="key" value="ypRKgO" />
                            <input type="hidden" name="txnid" value={txnId} />
                            <input type="hidden" name="productinfo" value="INRx" />
                            <input type="hidden" name="amount" value={amount} />
                            <input type="hidden" name="email" value={email} />
                            <input type="hidden" name="firstname" value={name} />
                            <input type="hidden" name="udf1" value={tokenId} />
                            <input type="hidden" name="udf2" value={symbol} />
                            <input type="hidden" name="udf3" value={type} />
                            <input type="hidden" name="udf4" value="" />
                            <input type="hidden" name="udf5" value="" />

                            {/* <input type="hidden" name="lastname" value="gupta" /> */}
                            <input type="hidden" name="surl" value="https://trst.ink/api/paySuccessurlPayu" />
                            <input type="hidden" name="furl" value="https://trst.ink/api/payfailedurlPayu" />
                            <input type="hidden" name="phone" value={phone} />

                            <input type="hidden" name="hash" value={hash} />
                            <div className="invoice-title">
                                <div id="main-title">
                                    <input
                                        type="text"
                                        name="Payment"
                                        style={{ border: 'none', background: "#fff" }}
                                        // disabled
                                        value={pay}

                                    />
                                    <span>#{generateRandomString()}</span>
                                </div>

                                <span id="date">{currentTime}</span>
                            </div>

                            <div className="invoice-details">
                                <table className="invoice-table">
                                    <thead>
                                        <tr>
                                            <td>Info</td>
                                            <td>Details</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    <tr className="row-data">
                                            <td>Name</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    dir="rtl"
                                                    style={{ border: 'none', background: "#fff" }}
                                                    value={name}
                                                // disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="row-data">
                                            <td>Mobile</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    dir="rtl"
                                                    style={{ border: 'none', background: "#fff" }}
                                                    value={phone}
                                                // disabled
                                                />
                                            </td>
                                        </tr>

                                        <tr className="row-data">
                                            <td>Email</td>
                                            <td>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    dir="rtl"
                                                    style={{ border: 'none', background: "#fff" }}
                                                    value={email}
                                                // disabled
                                                />
                                            </td>
                                        </tr>

                                        <tr className="row-data">
                                            <td>Amount </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="amount"
                                                    style={{ border: 'none', background: "#fff" }}
                                                    // disabled
                                                    value={amount}
                                                    dir="rtl"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="invoice-footer justify-content-between">
                            <div>
                                {isError && <p className="text-danger">Error: {isErrorMsg}</p>}
                            </div>
                                {loader ? (
                                  <button class="btn-pay btn-primary fw-bold" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    Loading...
                                    </button>
                                                                              
                                ) : (
                                    <div >
                                        {!btnPay ? <div  
                                            className="btn-pay btn-primary fw-bold"
                                            onClick={() => {
                                            if (!btnPay) {
                                                setLoader(true);  // Show loader immediately
                                                setTimeout(() => {
                                                setLoader(false);  // Hide loader after a delay
                                                setIsError(true);   // Show error message after the loader
                                                setTimeout(() => {
                                                    setIsError(false);  // Hide error message after another 5 seconds
                                                }, 5000);  // Hide error after 5 seconds
                                                }, 1000);  // Delay the error showing after 1 second
                                            }
                                            }}
                                        >
                                            PAY NOW &#8594;
                                        </div>:<button  
                                            type="submit" 
                                            className="btn-pay btn-primary fw-bold"
                                            disabled={!btnPay}  // 🔴 Button disable hai agar btnPay false hai
                                        >
                                            PAY NOW &#8594;
                                        </button>}
                                        </div>
                                )}
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>


    );
}

export default PaymentAppPayu;
